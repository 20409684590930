import { Box } from "@mui/material";

const MessageBox = ({ message, rootStyles, ...otherProps }) => {
  return (
    <Box
      sx={{
        typography: "subtitle1",
        color: "custom.grey_2",
        textAlign: "center",
        cursor: otherProps.onClick ? "pointer" : "default",

        "&:hover": {
          color: otherProps.onClick ? "custom.light" : "custom.grey_2"
        },

        ...rootStyles,
      }}
      {...otherProps}
    >
      {message}
    </Box>
  );
};

export default MessageBox;
