import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import CustomMenu from "components/Reuseable/CustomMenu";
import { truncateString } from "utilities/trucateString";
import { destroyCookies } from "utilities/destroyCookies";
import { setUser } from "redux/user/user.slice";
import { setIsLoggedIn } from "redux/auth/auth.slice";
import { useAuthContext } from "context/AuthContext";
import { useToasterContext } from "context/ToasterContext";
import Logo from "components/Reuseable/Logo";
import Locations from "./Locations";

const Header = () => {
  const navigate = useNavigate();

  const alert = useToasterContext();
  const { mutate } = useAuthContext();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  const logOut = async () => {
    destroyCookies();
    dispatch(setUser(null));
    dispatch(setIsLoggedIn(false));
    await mutate();
    alert("Logout successful", "success");
    navigate("/");
    window.location.reload();
  };

  return (
    <Box
      sx={{
        height: "7rem",
        boxShadow: 2,
        bgcolor: "custom.blue_4",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "1rem 1.5rem",
        zIndex: 100,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Logo src="/assets/logos/medlivery_white.png" rootStyles={{ height: "6rem", width: "6rem" }} />
        <Locations rootStyles={{ marginLeft: "1.5rem" }} />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
        {currentUser?.display_name || currentUser?.first_name ? (
          <Box
            sx={{
              marginRight: "1rem",
              typography: "subtitle1",
              color: "custom.white",
            }}
          >
            {truncateString(currentUser?.display_name || currentUser?.first_name, 12)}
          </Box>
        ) : null}

        <CustomMenu
          CustomIconLink={currentUser?.photo_url ? currentUser.photo_url : "/assets/others/user_placeholder.jpg"}
          alt={currentUser?.display_name || currentUser?.first_name}
          menuStyles={{ minWidth: "20rem" }}
          items={[
            {
              itemName: "Profile",
              iconImage: "/assets/icons/profile.png",
              pathname: "/profile",
            },
            {
              itemName: "Support",
              iconImage: "/assets/icons/support.png",
              pathname: "/support",
            },
            {
              itemName: "Logout",
              iconImage: "/assets/icons/logout.png",
              fn: async () => await logOut(),
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Header;
