import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "components/Reuseable/ProtectedRoute";
import ErrorBoundary from "components/Reuseable/ReactErrorBoundary";

import Login from "pages/Login";
import Signup from "pages/Signup";
import Error from "pages/NotFound";

import Metrics from "pages/Metrics";
import Profile from "pages/Profile";
import Support from "pages/Support";
import Availability from "pages/Availability";
import Appointments from "pages/Appointments";
import PatientsList from "pages/PatientsList";
import AppointmentDetails from "pages/AppointmentDetails";
import FullPageInfo from "components/Reuseable/FullPageInfo";
import PatientRecord from "pages/PatientRecord";
import DiagnosisForm from "pages/DiagnosisForm";
import PrescriptionForm from "pages/PrescriptionForm";
import ReferralForm from "pages/ReferralForm";
import DownloadDiagnosisForm from "components/_Forms/DiagnosisForm/DownloadDiagnosisForm";
import DownloadReferralForm from "components/_Forms/ReferralForm/DownloadReferralForm";
import DownloadPrescriptionForm from "components/_Forms/PrescriptionForm/DownloadPrescriptionForm";

function App() {
  return (
    <ErrorBoundary>
      {process.env.REACT_APP_NODE_ENV === "pre_production" ? (
        <FullPageInfo message="We are launching soon!" />
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute redirectTo="/appointments" flag={true}>
                <Login />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/signup"
            element={
              <ProtectedRoute redirectTo="/appointments" flag={true}>
                <Signup />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/support"
            element={
              <ProtectedRoute>
                <Support />
              </ProtectedRoute>
            }
          />

          <Route
            path="/appointments/forms/view/diagnosis-form/:patientId/:doctorId/:appointmentId"
            element={<DownloadDiagnosisForm />}
            exact
          />

          <Route
            path="/appointments/forms/view/referral-form/:patientId/:doctorId/:appointmentId"
            element={<DownloadReferralForm />}
            exact
          />

          <Route
            path="/appointments/forms/view/prescription-form/:patientId/:doctorId/:appointmentId"
            element={<DownloadPrescriptionForm />}
            exact
          />

          <Route exact path="/" element={<ProtectedRoute />}>
            <Route path="/appointments" element={<Appointments />} exact />
            <Route path="/appointments/:appointmentId" element={<AppointmentDetails />} exact />
            <Route path="/metrics" element={<Metrics />} exact />
            <Route path="/availability" element={<Availability />} exact />

            <Route path="/patient-records/list-of-patients" element={<PatientsList />} exact />
            <Route path="/patient-records/list-of-patients/:patientId" element={<PatientRecord />} exact />

            <Route path="/appointments/forms/fill/diagnosis-form/:patientId/:appointmentId" element={<DiagnosisForm />} exact />
            <Route
              path="/appointments/forms/fill/prescription-form/:patientId/:appointmentId"
              element={<PrescriptionForm />}
              exact
            />
            <Route path="/appointments/forms/fill/referral-form/:patientId/:appointmentId" element={<ReferralForm />} exact />
          </Route>

          <Route path="*" element={<Error />} />
        </Routes>
      )}
    </ErrorBoundary>
  );
}

export default App;
