import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";

import { Box, Button, List } from "@mui/material";

import CustomListItem from "components/Sidebar/CustomListItem";
import AccountsHeader from "./AccountsHeader";
import { toggleSidebar } from "redux/settings/settings.slice";

const Sidebar = ({ items }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const [isHovered, setIsHovered] = React.useState(settings.expand_sidebar);

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 1000,
        height: "100vh",
        position: settings.expand_sidebar ? "relative" : "fixed",
        transition: "width .5s",
        backgroundColor: "white",
        borderRight: "1px solid #dcdcdc",
        width: isHovered || settings.expand_sidebar ? "27.5rem" : "7rem",
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {settings.selected_account && (
        <Box sx={{ borderBottom: "2px dotted #dcdcdc", padding: "1.25rem" }}>
          <AccountsHeader expand_sidebar={settings.expand_sidebar} isHovered={isHovered} />
        </Box>
      )}

      <List sx={{ overflowX: "hidden", "& .Mui-disabled": { color: "custom.blue_2", opacity: 1 } }}>
        {items.map((el) => (
          <CustomListItem
            data={el}
            key={nanoid()}
            location={location}
            expand_sidebar={settings.expand_sidebar}
            isHovered={isHovered}
          />
        ))}
      </List>

      {(isHovered || settings.expand_sidebar) && (
        <Button
          variant={settings.expand_sidebar ? "contained" : "outlined"}
          onClick={() => dispatch(toggleSidebar())}
          sx={{
            position: "absolute",
            right: "1rem",
            bottom: "1rem",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            padding: "0.25rem 0",
            typography: "subtitle3",
          }}
        >
          {settings.expand_sidebar ? "Unpin" : "Pin"}
        </Button>
      )}
    </Box>
  );
};

export default Sidebar;
