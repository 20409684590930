import React from "react";
import { Helmet } from "react-helmet";

import LayoutBody from "components/Reuseable/LayoutBody";
import LazyLoadingWrapper from "components/Reuseable/LazyLoadingWrapper";

const RenderReferralForm = React.lazy(() => import("components/_Forms/ReferralForm"));

const ReferralForm = () => {
  return (
    <>
      <Helmet>
        <title>Medlivery Doctor | Referral Form</title>
      </Helmet>

      <LayoutBody>
        <LazyLoadingWrapper>
          <RenderReferralForm />
        </LazyLoadingWrapper>
      </LayoutBody>
    </>
  );
};

export default ReferralForm;