import { Helmet } from "react-helmet";

import RenderSignup from "components/_Signup";
import Layout from "components/Reuseable/Layout";

const Signup = () => {
  return (
    <>
      <Helmet>
        <title>Medlivery Doctor | Signup</title>
      </Helmet>

      <Layout showFooter={true}>
        <RenderSignup />
      </Layout>
    </>
  );
};

export default Signup;
