import * as React from "react";
import { nanoid } from "nanoid";
import { NavLink } from "react-router-dom";

import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MenuIcon from "@mui/icons-material/Menu";

const MenuOption = ({ image, name }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    {image ? <img src={image} alt={name} style={{ width: "2rem", height: "2rem", marginRight: "1rem" }} /> : null}
    <Box>{name}</Box>
  </Box>
);

export default function CustomMenu({
  CustomIcon,
  CustomIconLink,
  alt,
  items,
  position,
  transformOrigin,
  menuIconStyles = {},
  menuStyles = {},
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current.focus();
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          position: "relative",
          height: "5.5rem",
          width: "5.5rem",
          borderRadius: "50%",
          overflow: "hidden",
          boxShadow: 2,
          border: "1px solid white",
          backgroundColor: "custom.white",

          "&:hover": { backgroundColor: "custom.white" },

          ...menuIconStyles,
        }}
      >
        {CustomIconLink ? (
          <Box
            component="img"
            src={CustomIconLink}
            alt={alt ?? "User placeholder"}
            sx={{ position: "absolute", height: "100%", width: "100%" }}
          />
        ) : CustomIcon ? (
          CustomIcon
        ) : (
          <MenuIcon sx={{ fontSize: "4rem", color: "custom.white" }} />
        )}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={position ?? "bottom-end"}
        transition
        style={{ zIndex: 400 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: transformOrigin ?? "right top" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    width: "max-content",
                    minWidth: "20rem",
                    boxShadow: 5,
                    borderRadius: ".5rem",
                    ...menuStyles,
                  }}
                >
                  {items.map((item) => (
                    <MenuItem
                      key={nanoid()}
                      sx={{ padding: 0 }}
                      onClick={(e) => {
                        item.fn && item.fn();
                        handleClose(e);
                      }}
                    >
                      <>
                        {item.pathname && (
                          <NavLink to={item.pathname} style={{ ...linkStyles }}>
                            <MenuOption image={item.iconImage} name={item.itemName} />
                          </NavLink>
                        )}

                        {item.extURL && (
                          <Box
                            component="a"
                            sx={{ ...linkStyles }}
                            href={item.extURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MenuOption image={item.iconImage} name={item.itemName} />
                          </Box>
                        )}

                        {!item.pathname && !item.extURL ? (
                          <Box sx={{ ...linkStyles }}>
                            <MenuOption image={item.iconImage} name={item.itemName} />
                          </Box>
                        ) : null}
                      </>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const linkStyles = {
  minWidth: "13rem",
  width: "100%",
  display: "block",
  textDecoration: "none",
  color: "black",
  padding: ".75rem 1rem",
  fontSize: "1.4rem",
  lineHeight: "1.5rem",
  textAlign: "left",
};
