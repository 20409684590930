import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./user/user.slice";
import authReducer from "./auth/auth.slice";
import accountsReducer from "./accounts/accounts.slice";
import settingsReducer from "./settings/settings.slice";
import availabilityReducer from "./availability/availability.slice";
import patientRecordsReducer from "./patient_records/patient_records.slice";
import appointmentTypeReducer from "./appointment_type/appointment_type.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    settings: settingsReducer,
    accessed_accounts: accountsReducer,
    availabilities: availabilityReducer,
    patient_records: patientRecordsReducer,
    appointment_types: appointmentTypeReducer,
  },
});
