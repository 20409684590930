import React from "react";

import { Box, Container } from "@mui/material";

import Logo from "components/Reuseable/Logo";

export default function Header() {
  return (
    <Box
      component="header"
      sx={{
        width: "100%",
        height: "max-content",
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 11,
        borderBottom: "1px solid #dcdcdc",
      }}
    >
      <Container
        maxWidth="md"
        component="nav"
        sx={{
          height: "8rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Logo src="/assets/logos/favicon_medlivery.png" isLink={true} link="/" />
        </Box>
      </Container>
    </Box>
  );
}
