import { nanoid } from "nanoid";

import { Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const MenuOption = ({ image, name, value, selectedValue }) => (
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: value && selectedValue && value === selectedValue.value ? "calc(100% - 2.25rem)" : "100%",
      }}
    >
      {image ? <img src={image} alt={name} style={{ width: "2rem", height: "2rem", marginRight: "1.5rem" }} /> : null}
      <Box sx={{ whiteSpace: "normal", wordBreak: "break-word", width: "100%" }}>{name}</Box>
    </Box>

    {value && selectedValue && value === selectedValue.value ? (
      <CheckCircleIcon sx={{ color: "blue", fontSize: "2rem" }} />
    ) : null}
  </Box>
);

const CustomMenuList = ({
  menuItems,
  open,
  menuAnchorEl,
  setMenuAnchorEl,
  setOpenMenu,
  selectedValue,
  rootStyles = {},
}) => {
  return open === true ? (
    <Menu id="basic-menu" anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setOpenMenu(false)}>
      {menuItems
        .map((el, idx) =>
          el.isActive === false ? null : (
            <MenuItem
              key={nanoid()}
              sx={{
                typography: "subtitle2",
                padding: ".25rem 1rem",
                width: "20rem",
                ...rootStyles,
              }}
              disabled={el.disabled}
              onClick={(e) => {
                if (el.fn) {
                  el.fn(el);
                  setOpenMenu(false);
                  setMenuAnchorEl && setMenuAnchorEl(false);
                  return;
                }

                setOpenMenu(false);
                setMenuAnchorEl && setMenuAnchorEl(false);
                return null;
              }}
            >
              <MenuOption name={el.title} value={el.value} selectedValue={selectedValue} image={el.iconImage} />
            </MenuItem>
          )
        )
        .filter((el) => el)}
    </Menu>
  ) : null;
};

export default CustomMenuList;
