import { Container } from "@mui/material";
import Footer from "components/Footer";
import Header from "../Header";

const Layout = ({ children, showFooter = false }) => {
  return (
    <>
      <Header />

      <Container
        component="main"
        maxWidth="md"
        sx={{
          height: "100%",
          position: "relative",
          minHeight: "calc(100vh - 8rem)",
        }}
      >
        {children}
      </Container>

      {showFooter === true && <Footer />}
    </>
  );
};

export default Layout;
