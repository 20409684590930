import useSWR from "swr";
import axios from "axios";

import { getCookie } from "utilities/getCookie";

export const fetcherWithToken = (params) => (url) => {
  const token = getCookie("token");

  return axios.request({
    method: "GET",
    url,
    headers: { authorization: token ? `Bearer ${token}` : null, "Content-Type": "application/json" },
    params,
  });
};

export const useFetchWithToken = ({ pathname, condition = true, options = {}, params = {} }) => {
  const url = `${process.env.REACT_APP_API_URL}/${pathname}`;

  const { data, error, mutate } = useSWR(condition ? url : null, fetcherWithToken(params), options);

  return {
    data: data?.data,
    isLoading: !error && !data && condition,
    isError: error,
    mutate,
  };
};
