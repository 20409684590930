import React from "react";
import { Helmet } from "react-helmet";

import LayoutBody from "components/Reuseable/LayoutBody";
import LazyLoadingWrapper from "components/Reuseable/LazyLoadingWrapper";

const RenderAvailability = React.lazy(() => import("components/_Availability"))

const Availability = () => {
  return (
    <>
      <Helmet>
        <title>Medlivery Doctor | Availability</title>
      </Helmet>

      <LayoutBody>
        <LazyLoadingWrapper>
          <RenderAvailability />
        </LazyLoadingWrapper>
      </LayoutBody>
    </>
  );
};

export default Availability;
