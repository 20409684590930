import { createSlice } from "@reduxjs/toolkit";

let initialState = { records: {}, currentPage: 1, totalNoOfDocs: 0, rowsPerPage: 5 };

const patientRecordsSlice = createSlice({
  name: "patient_records",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setRecords: (state, action) => {
      const { page, records } = action.payload;
      state.records[page] = records;
    },
    resetRecords: (state) => {
      state.records = {};
    },
    setTotalNoOfDocs: (state, action) => {
      state.totalNoOfDocs = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setRecords, resetRecords, setTotalNoOfDocs } = patientRecordsSlice.actions;
export default patientRecordsSlice.reducer;
