import React from "react";
import { Helmet } from "react-helmet";

import LayoutBody from "components/Reuseable/LayoutBody";
import LazyLoadingWrapper from "components/Reuseable/LazyLoadingWrapper";

const RenderMetrics = React.lazy(() => import("components/_Metrics"));

const Metrics = () => {
  return (
    <>
      <Helmet>
        <title>Medlivery Doctor | Metrics</title>
      </Helmet>

      <LayoutBody>
        <LazyLoadingWrapper>
          <RenderMetrics />
        </LazyLoadingWrapper>
      </LayoutBody>
    </>
  );
};

export default Metrics;
