import Box from "@mui/system/Box";
import { useSelector } from "react-redux";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { sidebar_items } from "constants/sidebar-items";

const LayoutBody = ({ children }) => {
  const settings = useSelector((state) => state.settings);

  return (
    <Box
      sx={{
        ...(settings.expand_sidebar === false
          ? { position: "absolute", top: 0, left: "7rem", width: "calc(100vw - 7rem)" }
          : { display: "flex" }),
      }}
    >

      <Sidebar items={sidebar_items} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          ...(settings.expand_sidebar === false && { width: "100%" }),
          ...(settings.expand_sidebar === true && { width: "calc(100vw - 27.5rem)" }),
        }}
      >
        <Header />
        <Box sx={{ height: "calc(100vh - 7rem)", padding: "1rem 1.5rem", overflow: "auto" }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default LayoutBody;
