import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  is_availability_already_fetched: false,
  last_selected_location: "",
  availabilities: {},
};

const appointmentTypesSlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    setIsAvailabilityAlreadyFetched: (state, action) => {
      state.is_availability_already_fetched = action.payload;
    },
    setLastSelectedLocation: (state, action) => {
      state.last_selected_location = action.payload;
    },
    setAvailabilities: (state, action) => {
      const { availabilityId, timings, type } = action.payload;

      if (type === "replace") state.availabilities = {};

      if (!timings || (Array.isArray(timings) && timings.length === 0)) {
        if (state.availabilities.hasOwnProperty(availabilityId)) {
          delete state.availabilities[availabilityId];
          return;
        }

        return;
      }

      state.availabilities[availabilityId] = action.payload;
    },
  },
});

export const { setIsAvailabilityAlreadyFetched, setLastSelectedLocation, setAvailabilities } = appointmentTypesSlice.actions;

export default appointmentTypesSlice.reducer;
