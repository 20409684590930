import React from 'react';
import useWindowPos from './useWindowPos';

export default function useGetPositions(dependencies = []) {
  const { dimensions } = useWindowPos();
  const posRef = React.useRef(null);
  const [positions, setPositions] = React.useState(undefined);

  React.useEffect(() => {
    setPositions(undefined);

    if (posRef.current) {
      let positions = posRef.current.getBoundingClientRect();
      setPositions(positions);
    }

    return () => setPositions(undefined);
  }, [...dependencies, dimensions?.width]);

  return { posRef, positions, setPositions };
}
