import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import CustomMenuList from "components/Reuseable/CustomMenuList";
import { setLocations, setSelectedLocation } from "redux/settings/settings.slice";

const Locations = ({ rootStyles }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const accounts = useSelector((state) => state.accessed_accounts?.accounts);

  const menuRef = React.useRef();
  const [openMenu, setOpenMenu] = React.useState(false);

  const [_locations, _setLocations] = React.useState([]);
  const [selectedLocationDetails, setSelectedLocationDetails] = React.useState();

  // Retrieve locations from the account selected
  React.useEffect(() => {
    if (settings.selected_account) {
      const locations =
        Array.isArray(accounts) && settings.selected_account
          ? accounts.find((acc) => acc._id === settings.selected_account)?.locations
          : null;

      if (Array.isArray(locations)) {
        _setLocations([
          { title: "All Locations", value: "admin", fn: () => dispatch(setSelectedLocation("admin")) },
          ...locations.map((el) => ({
            title: el.name,
            value: el._id,
            fn: () => dispatch(setSelectedLocation(el._id)),
          })),
        ]);

        dispatch(
          setLocations([
            { title: "All Locations", value: "admin" },
            ...locations.map((el) => ({ title: el.name, value: el._id })),
          ])
        );
      } else dispatch(setLocations([]));
    }
  }, [settings.selected_account]);

  // Retrieve the name from selected location
  React.useEffect(() => {
    if (Array.isArray(_locations) && settings.selected_location) {
      const selectedLocation = Array.isArray(_locations)
        ? _locations.find((loc) => loc.value === settings.selected_location)
        : null;

      if (selectedLocation) setSelectedLocationDetails(selectedLocation);
    }
  }, [_locations, settings.selected_location]);

  return (
    settings.selected_location && (
      <>
        <Box
          ref={menuRef}
          onClick={() => setOpenMenu(true)}
          sx={{
            border: "1px solid white",
            color: "white",
            padding: ".5rem",
            borderRadius: ".5rem",
            typography: "subtitle2",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "20rem",
            ...rootStyles,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon />
            <Box
              sx={{
                width: "14rem",
                paddingLeft: "0.5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selectedLocationDetails?.title}
            </Box>
          </Box>
          <ArrowDropDownIcon />
        </Box>

        <CustomMenuList
          open={openMenu}
          selectedValue={selectedLocationDetails}
          setOpenMenu={setOpenMenu}
          menuAnchorEl={menuRef.current}
          menuItems={_locations}
          rootStyles={{ width: "20rem" }}
        />
      </>
    )
  );
};

export default Locations;
