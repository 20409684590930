import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Box, Grid } from "@mui/material";

import CustomTextField from "components/Reuseable/CustomTextField";
import FormWrapper from "components/Reuseable/FormWrapper";
import CustomDropdown from "components/Reuseable/CustomDropdown";
import { DOCTOR_REQUEST } from "constants/urls";
import { useToasterContext } from "context/ToasterContext";
import { makeRequest } from "utilities/fetcher";

const SignupForm = () => {
  const alert = useToasterContext();

  const formik = useFormik({
    initialValues: { countryCode: "+267", mobile: "" },
    validationSchema: Yup.object().shape({
      mobile: Yup.string()
        .required("Please enter your mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .test("len", "Please enter a valid phone number", (val) => {
          if (!val) return true;
          else return val.toString().length === 8;
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      await makeRequest({
        pathname: DOCTOR_REQUEST,
        values: { mobile: `${values.countryCode}${values.mobile}` },
        alert,
      });

      resetForm();
    },
  });

  return (
    <Box style={{ justifySelf: "center" }}>
      <FormWrapper formik={formik} heading="Create account request" rootStyles={{ width: "47rem" }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <CustomDropdown
              value={formik.values.countryCode}
              disabled={true}
              menuItems={[{ title: "+267" }]}
              rootStyles={{ width: "15rem" }}
            />

            <CustomTextField
              id="mobile"
              label="Mobile Number"
              type="number"
              value={formik.values.mobile}
              handleChange={formik.handleChange}
              handleblur={formik.handleBlur}
              disabled={formik.isSubmitting}
              errors={formik.errors.mobile}
              touched={formik.touched.mobile}
            />
          </Box>

          {formik.errors.mobile && formik.touched.mobile ? null : (
            <Box
              sx={{
                textAlign: "center",
                typography: "subtitle3",
                color: "custom.grey_2",
                marginTop: ".75rem",
              }}
            >
              We'll get back to you after we receive your request
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center", typography: "subtitle2" }}>
          <Box
            sx={{ border: "2px dotted #dcdcdc", borderRadius: "1rem", padding: ".5rem", width: "80%", margin: "auto" }}
          >
            By creating an account request, you agree to our{" "}
            <a href={`${process.env.REACT_APP_PATIENT_UI_URL}/terms-of-use`} target="_blank" rel="noreferrer">
              terms of use
            </a>
            .
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} sx={{ textAlign: "center", typography: "subtitle1" }}>
          <Link to="/">I already have an account</Link>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default SignupForm;
