import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useFetchWithToken } from "hooks/useFetchWithToken";
import { destroyCookies } from "utilities/destroyCookies";
import { CHECK_IS_LOGGED_IN } from "constants/urls";
import { useToasterContext } from "./ToasterContext";
import { setUser } from "redux/user/user.slice";
import { setIsLoggedIn } from "redux/auth/auth.slice";
import { setAccounts } from "redux/accounts/accounts.slice";
import { setSelectedAccount, setSelectedLocation } from "redux/settings/settings.slice";

const AuthContext = React.createContext(null);

export const useAuthContext = () => React.useContext(AuthContext);

export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useToasterContext();

  const settings = useSelector((state) => state.settings);

  // To check if a user is logged in
  const { data: response, isError, isLoading, mutate } = useFetchWithToken({ pathname: CHECK_IS_LOGGED_IN });

  React.useEffect(() => {
    // If status is error or warning, then logout
    if (response?.status === "error" && !isLoading && !isError) {
      const logout = async () => {
        destroyCookies();
        dispatch(setUser(null));
        dispatch(setIsLoggedIn(false));
        await mutate();
        navigate("/");
        alert("Your session has expired", "info");
        // window.location.reload();
      };

      return logout();
    }

    // If logged in, setUser to payload
    if (response?.payload?.isLoggedIn && response?.payload?.doctor && !isLoading && !isError) {
      if (Array.isArray(response.payload.accounts) && response.payload.accounts.length) {
        dispatch(setAccounts(response.payload.accounts));
        dispatch(setSelectedAccount(response.payload.accounts[0]._id));
        dispatch(setSelectedLocation(settings?.selected_location ? settings.selected_location : "admin"));
      }

      dispatch(setUser(response.payload.doctor));
      dispatch(setIsLoggedIn(response.payload.isLoggedIn));

      return;
    }

    // If not logged in, logout and setUser to null
    if (!response?.payload?.isLoggedIn && !isLoading && !isError) {
      destroyCookies();
      dispatch(setUser(null));
      dispatch(setIsLoggedIn(false));
      mutate();
      // window.location.reload();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.status]);

  return <AuthContext.Provider value={{ isLoading, isError, mutate }}>{children}</AuthContext.Provider>;
}
