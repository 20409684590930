import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  is_appt_types_already_fetched: false,
  last_selected_location: "",
  appointment_types: [],
  all_appointment_types: null,
};

const appointmentTypeSlice = createSlice({
  name: "appointment_type",
  initialState,
  reducers: {
    setIsApptTypesAlreadyFetched: (state, action) => {
      state.is_appt_types_already_fetched = action.payload;
    },
    setLastSelectedLocation: (state, action) => {
      state.last_selected_location = action.payload;
    },
    setAppointmentTypes: (state, action) => {
      state.appointment_types = action.payload;
    },
    setAllAppointmentTypes: (state, action) => {
      state.all_appointment_types = action.payload;
    },
    addAppointmentType: (state, action) => {
      state.appointment_types = [...state.appointment_types, ...action.payload];
    },
    updateAppointmentType: (state, action) => {
      state.appointment_types = state.appointment_types.map((el) => (el._id === action.payload.id ? action.payload.data : el));
    },
    deleteAppointmentType: (state, action) => {
      state.appointment_types = state.appointment_types.filter((el) => el._id !== action.payload);
    },
  },
});

export const {
  setIsApptTypesAlreadyFetched,
  setLastSelectedLocation,
  setAppointmentTypes,
  setAllAppointmentTypes,
  addAppointmentType,
  updateAppointmentType,
  deleteAppointmentType,
  setNoOfDocs,
} = appointmentTypeSlice.actions;

export default appointmentTypeSlice.reducer;
