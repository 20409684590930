import React from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

const AccountsHeader = ({ isHovered, expand_sidebar }) => {
  const settings = useSelector((state) => state.settings);
  const account = useSelector((state) =>
    Array.isArray(state.accessed_accounts?.accounts) && state.accessed_accounts?.accounts.length
      ? state.accessed_accounts?.accounts.find((acc) => acc._id === settings.selected_account)
      : ""
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", position: "relative", width: "100%" }}>
      <Box
        sx={{
          height: "4.25rem",
          width: "4.25rem",
          backgroundColor: "custom.blue_4",
          borderRadius: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          typography: "h3",
          color: "white",
        }}
      >
        {account.initials}
      </Box>

      {(isHovered || expand_sidebar) && (
        <Box
          sx={{
            typography: "body2",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "calc(100% - 7rem)",
            overflow: "hidden",
            position: "absolute",
            left: "6rem",
          }}
        >
          {account.account_name}
        </Box>
      )}
    </Box>
  );
};

export default AccountsHeader;
