import React from "react";
import { useTheme } from "@emotion/react";
import { nanoid } from "nanoid";

import {
  Box,
  ClickAwayListener,
  FormHelperText,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import useGetPositions from "hooks/useGetPositions";
import MessageBox from "components/Reuseable/MessageBox";
import { truncateString } from "utilities/trucateString";

const CustomDropdown = ({
  id,
  label,
  heading = "Select",
  value,
  errors,
  touched,
  menuItems,
  disabled,
  trucateStringOn,
  handleChange = null,
  customHelperText,
  msgWhenMenuItemsNotPresent = "",
  onNoItemsMessage,
  showDropdownIcon = true,
  rootStyles = {},
  buttonStyles = {},
}) => {
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => setOpen(false);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const { posRef, positions } = useGetPositions();

  let errorBool = errors && touched;

  return (
    <Box sx={{ position: "relative", width: "100%", ...rootStyles }}>
      {label && (
        <InputLabel
          htmlFor={id}
          sx={{
            fontSize: "1.3rem",
            lineHeight: "max-content",
            color: errorBool ? "red" : "black",
            position: "absolute",
            top: 0,
            left: ".9rem",
            padding: "0 .5rem",
            transform: "translateY(-50%)",
            backgroundColor: "white",
          }}
        >
          {label}
        </InputLabel>
      )}

      <Box
        component="button"
        type="button"
        id={id}
        ref={posRef}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setMenuAnchorEl(e.target);
          handleToggle();
        }}
        sx={{
          width: "100%",
          height: "5rem",
          borderRadius: "5px",
          bgcolor: "custom.white",
          border: `1px solid ${errorBool ? "red" : theme.palette.custom.grey_1}`,
          outline: "none",
          fontSize: "1.6rem",
          padding: "0 14px",
          display: "flex",
          alignItems: "center",
          WebkitAlignItems: "center",
          justifyContent: "space-between",
          WebkitJustifyContent: "space-between",
          cursor: disabled ? "cursor" : "pointer",
          color: value ? (disabled ? "custom.grey_2" : "black") : errorBool ? "red" : "custom.grey_2",
          ...buttonStyles,
        }}
      >
        {truncateString(value ? value : heading, trucateStringOn)}

        {showDropdownIcon && !disabled && Array.isArray(menuItems) && menuItems.length ? (
          <KeyboardArrowDownIcon
            sx={{
              color: `${errorBool ? "red" : "custom.grey_2"}`,
              fontSize: "2.5rem",
              pointerEvents: "none",
            }}
          />
        ) : null}
      </Box>

      {Array.isArray(menuItems) && menuItems.length ? (
        <Popper
          open={open}
          anchorEl={menuAnchorEl}
          role={undefined}
          placement="bottom-start"
          transition
          style={{ zIndex: 5000 }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{
                      width: positions?.width ? positions.width : "100%",
                      maxHeight: "25rem",
                      overflow: "auto",
                    }}
                  >
                    {Array.isArray(menuItems) && menuItems.length ? (
                      menuItems.map((el) => (
                        <MenuItem
                          key={nanoid()}
                          sx={{
                            whiteSpace: "normal",
                            typography: "subtitle1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "1rem",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                          onClick={(e) => {
                            handleChange && handleChange(id, el);
                            handleClose();
                          }}
                        >
                          {el.title}
                          {el.title === value && <CheckCircleIcon color="primary" sx={{ fontSize: "2rem" }} />}
                        </MenuItem>
                      ))
                    ) : (
                      <MessageBox message={onNoItemsMessage} rootStyles={{ padding: "0 .5rem" }} />
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ) : null}

      {errorBool || customHelperText || msgWhenMenuItemsNotPresent ? (
        <FormHelperText
          sx={{
            typography: "subtitle2",
            padding: ".5rem 0 0 1rem",
            margin: 0,
            color: `${errorBool ? "red" : "black"}`,
          }}
        >
          {errorBool
            ? errors
            : Array.isArray(menuItems) && menuItems.length === 0 && msgWhenMenuItemsNotPresent
            ? msgWhenMenuItemsNotPresent
            : customHelperText}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default React.memo(CustomDropdown);
