import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import FullPageInfo from "../FullPageInfo";

const ProtectedRoute = ({ children, redirectTo = "/", flag = false }) => {
  const { isLoading, isError } = useAuthContext();

  const currentUser = useSelector((state) => state.user.currentUser);
  const auth = useSelector((state) => state.auth);

  if (isError) {
    return (
      <FullPageInfo message="Something went wrong. Please try again later!" />
    );
  }

  if (isLoading) {
    return <FullPageInfo />;
  }

  if (
    (!flag && !currentUser && !auth.isLoggedIn) ||
    (flag && currentUser && auth.isLoggedIn)
  )
    return <Navigate to={redirectTo} replace />;

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
