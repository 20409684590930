import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";

import ToasterContext from "context/ToasterContext";
import { store } from "redux/store";
import theme from "./utilities/theme";
import AuthContext from "context/AuthContext";
import ModalContext from "context/ModalContext";
import ScrollToTop from "components/Reuseable/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ToasterContext>
            <ModalContext>
              <AuthContext>
                <App />
              </AuthContext>
            </ModalContext>
          </ToasterContext>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
