import React from 'react';
import throttle from 'utilities/throttle';

export default function useWindowPos(dependencies = []) {
  const [dimensions, setDimensions] = React.useState();

  React.useEffect(() => {
    if (document?.body) {
      setDimensions({
        height: document.body.clientHeight,
        width: document.body.clientWidth,
      });
    }
  }, []);

  React.useEffect(() => {
    const debouncedHandleResize = throttle(function handleResize() {
      if (document?.body) {
        setDimensions({
          height: document.body.clientHeight,
          width: document.body.clientWidth,
        });
      }
    }, 500);

    window.addEventListener('resize', debouncedHandleResize);

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, dependencies);

  return { dimensions };
}
