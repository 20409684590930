import React from "react";

import { SnackbarProvider, useSnackbar } from "notistack";

const SnackbarContext = React.createContext(null);
export const useToasterContext = () => React.useContext(SnackbarContext);

const MyApp = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const setStateSnackbarContext = (message, variant) =>
    enqueueSnackbar(message, { variant });

  return (
    <SnackbarContext.Provider value={setStateSnackbarContext}>
      {children}
    </SnackbarContext.Provider>
  );
};

const ToasterProvider = ({ children }) => {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp>{children}</MyApp>
    </SnackbarProvider>
  );
};

export default ToasterProvider;
