import React from "react";
import "./index.css";
import { Box } from "@mui/material";

const SpinnerAdv = () => {
  return (
    <Box className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Box>
  );
};

export default SpinnerAdv;
