export const sidebar_items = [
  // {
  //   heading: "Metrics",
  //   href: "/metrics",
  //   comparisonString: "metrics",
  //   icon: "/assets/icons/metrics.png",
  //   listItems: [
  //     {
  //       heading: "Create Customer",
  //       href: "/onboard-customer?tab=create-customer",
  //       comparisonString: "create-customer",
  //     },
  //     {
  //       heading: "Create Team and TeamBot",
  //       href: "/onboard-customer?tab=create-team-and-teambot",
  //       comparisonString: "create-team-and-teambot",
  //     },
  //     {
  //       heading: "Manage Bandwidth",
  //       comparisonString: "manage-bandwidth",
  //     },
  //   ],
  // },
  {
    heading: "Appointments",
    href: "/appointments",
    comparisonString: "appointments",
    icon: "/assets/icons/appointments.png",
  },
  {
    heading: "Availability",
    href: "/availability",
    comparisonString: "availability",
    icon: "/assets/icons/availability.png",
  },
  {
    heading: "Patient Records",
    href: "/patient-records/list-of-patients",
    comparisonString: "list-of-patients",
    icon: "/assets/icons/payments.png",
  },
];
