import React from "react";
import { Helmet } from "react-helmet";

import LayoutBody from "components/Reuseable/LayoutBody";
import LazyLoadingWrapper from "components/Reuseable/LazyLoadingWrapper";

const RenderPatientRecord = React.lazy(() => import("components/_PatientRecords/PatientRecord"));

const PatientRecord = () => {
  return (
    <>
      <Helmet>
        <title>Medlivery Doctor | Patient Record</title>
      </Helmet>

      <LayoutBody>
        <LazyLoadingWrapper>
          <RenderPatientRecord />
        </LazyLoadingWrapper>
      </LayoutBody>
    </>
  );
};

export default PatientRecord;
