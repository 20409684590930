import React from "react";
import { nanoid } from "nanoid";

import Modal from "@mui/material/Modal";

import { AnimatePresence, motion } from "framer-motion";

const ModalContext = React.createContext();

export const useModalContext = () => React.useContext(ModalContext);

const variants = {
  initial: { y: 100, x: 0 },
  final: { y: 0, x: 0, transition: { duration: 0.5, type: "tween" } },
  exit: { y: 100, x: 0, transition: { duration: 0.5, type: "tween" } },
};

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClickOutside, setIsClickOutside] = React.useState(true);
  const [Component, setComponent] = React.useState(undefined);

  const handleModalOpen = () => setIsOpen(true);
  const handleModalClose = () => {
    setIsOpen(false);
    setComponent(null);
    setIsClickOutside(true);
  };

  return (
    <>
      <ModalContext.Provider value={{ handleModalOpen, handleModalClose, setIsClickOutside, setComponent }}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={() => isClickOutside && handleModalClose()}
          closeAfterTransition
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <div style={{ zIndex: 10001, width: "max-content" }}>
            <AnimatePresence>
              <motion.div key={nanoid()} variants={variants} initial="initial" animate="final" exit="exit">
                {Component}
              </motion.div>
            </AnimatePresence>
          </div>
        </Modal>

        {children}
      </ModalContext.Provider>
    </>
  );
};

export default ModalProvider;
