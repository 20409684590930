import Box from "@mui/system/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Logo from "../Logo";

const FullPageInfo = ({ showLoader = true, message = "" }) => {
  return (
    <Box sx={{ position: "fixed", height: "100vh", width: "100vw" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2.2rem",
        }}
      >
        {showLoader && !message ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress sx={{ marginRight: "1.5rem" }} />
            Loading...
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Logo src="/assets/logos/favicon_medlivery.png" />
            <Box>{message.trim()}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FullPageInfo;
