export const DOCTOR_REQUEST = "auth/doctor/signup_request";
export const DOCTOR_SIGNIN = "auth/doctor/signin";
export const UPDATE_PASSWORD = "auth/doctor/update_password";
export const CHECK_IS_LOGGED_IN = "auth/doctor/isLoggedIn";

export const UPDATE_PROFILE = "profile/doctor/update";
export const SET_PROFILE_PIC = "profile/doctor/set_profile_pic";

export const CREATE_APPOINTMENT_TYPE = "appointment_type/doctor/create";
export const GET_APPOINTMENT_TYPES = "appointment_type/doctor/get";
export const UPDATE_APPOINTMENT_TYPE = "appointment_type/doctor/update";
export const DELETE_APPOINTMENT_TYPE = "appointment_type/doctor/delete";

export const CREATE_AVAILABILITY = "availability/doctor/create";
export const GET_AVAILABILITIES = "availability/doctor/get";
export const UPDATE_AVAILABILITY = "availability/doctor/update";
export const DELETE_AVAILABILITY = "availability/doctor/delete";

export const GET_APPOINTMENTS = "appointment/doctor/get_appointments";
export const CANCEL_APPOINTMENT = "appointment/doctor/cancel_appointment";
export const COMPLETE_APPOINTMENT = "appointment/doctor/complete_appointment";
export const CONFIRM_APPOINTMENT = "appointment/doctor/confirm_appointment";
export const GET_APPOINTMENTS_COUNT = "appointment/doctor/get_appointments_count";
export const GET_APPOINTMENT_DETAILS = "appointment/doctor/get_appointment_details";
export const ADD_PATIENT_AND_BOOK_APPOINTMENT = "appointment/doctor/add_patient_and_book_appointment";
export const VERIFY_EMAIL_OR_MOBILE = "appointment/doctor/verify_email_or_mobile";
export const ADD_DIAGNOSIS_FORM = ({appointmentId, patientId}) => `appointment/doctor/add_diagnosis_form/${patientId}/${appointmentId}`
export const ADD_REFERRAL_FORM = ({appointmentId, patientId}) => `appointment/doctor/add_referral_form/${patientId}/${appointmentId}`
export const ADD_PRESCRIPTION_FORM = ({appointmentId, patientId}) => `appointment/doctor/add_prescription_form/${patientId}/${appointmentId}`

export const GET_PATIENTS_LIST = "records/doctor/get_patients_list";
export const GET_PATIENT_APPOINTMENTS = "records/doctor/get_patient_appointments";
export const GET_PATIENT_TRANSACTIONS = (patientId) => `records/doctor/get_patient_transactions/${patientId}`;
export const GET_PATIENT_RECORD = (patientId) => `records/doctor/get_patient_record/${patientId}`;
export const GET_PATIENT_DETAILS = (patientId) => `records/doctor/get_patient_details/${patientId}`;

export const GET_SERVER_TIME = "common/get_server_time";

export const GET_APPOINTMENT_SLOTS = "appointment_slot/patient/get";
