import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import CustomButton from "components/Reuseable/Button";

const FormWrapper = ({ heading, children, formik, rootStyles, buttonText, formProps = {} }) => {
  return (
    <Grid
      container
      rowSpacing={2}
      component="form"
      {...formProps}
      sx={{
        boxShadow: 3,
        width: "40rem",
        padding: "3rem 2rem",
        borderRadius: "1rem",

        "@media (max-width: 500px)": { width: "100% !important" },

        ...rootStyles,
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          typography: "h3",
          marginBottom: "2rem",
        }}
      >
        {heading}
      </Box>

      {children}

      <Grid item xs={12} sm={12} sx={{ textAlign: "center", marginTop: "1rem" }}>
        <CustomButton
          type="submit"
          text={buttonText ?? "Create"}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default FormWrapper;
