import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GET_APPOINTMENT_DETAILS } from "constants/urls";
import { useFetchWithToken } from "hooks/useFetchWithToken";
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import SpinnerAdv from "components/Reuseable/SpinnerAdv";
import MessageBox from "components/Reuseable/MessageBox";
import { getName } from "utilities/getName";
import { toUpperCaseFirstLetter } from "utilities/toUpperCaseFirstLetter";
import { calculate_age } from "utilities/dateHelperFunctions";

const styles = {
  tableCell: { padding: "8px", width: "50%", fontSize: "14px", border: "1px solid black" },
  tableCell_width_5: { width: "5rem" },

  heading: { fontSize: "18px", fontWeight: 600, padding: ".5rem" },

  tableCellHeading: { fontWeight: 600 },
};

const DownloadPrescriptionForm = () => {
  const params = useParams();
  const settings = useSelector((state) => state.settings);

  const printContainerRef = useRef();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Fetch patient details
  const [formDetails, setFormDetails] = useState({});

  const { data, isLoading, isError } = useFetchWithToken({
    pathname: `${GET_APPOINTMENT_DETAILS}/${params.appointmentId}`,
    condition: params?.appointmentId && settings.selected_account && settings.selected_location ? true : false,
    params: {
      account: settings.selected_account,
      location: settings.selected_location,
      required_fields: { prescription_form: 1, patient: 1, doctor: 1 },
    },
    options: {
      dedupingInterval: 3600000,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  useEffect(() => {
    if (data?.payload?.patient && data?.payload?.doctor && data?.payload?._id) {
      setFormDetails((prev) => ({
        ...prev,
        doctor: data.payload.doctor,
        patient: data.payload.patient,
        appointmentId: data.payload._id,
      }));
    }

    if (
      data?.payload?.prescription_form &&
      typeof data?.payload?.prescription_form === "object" &&
      Object.keys(data?.payload?.prescription_form).length
    ) {
      setFormDetails((prev) => ({ ...prev, prescription_form: data?.payload?.prescription_form }));
    }
  }, [data?.payload?.patient, data?.payload?.doctor, data?.payload?.prescription_form]);

  const is_signature_available = useMemo(() => {
    if (typeof formDetails?.doctor?.signature_uri === "string" && Boolean(formDetails?.doctor?.signature_uri.trim())) {
      return true;
    } else return false;
  }, [formDetails?.doctor?.signature_uri]);

  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      if (formDetails && typeof formDetails === "object" && Object.keys(formDetails).length && params.appointmentId) {
        if (is_signature_available) {
          if (typeof window !== "undefined" && printContainerRef.current && isImageLoaded) {
            document.title = `Referral_Form_${formDetails?.appointmentId}`;
            window.print();
            window.close();
          }
        } else {
          if (typeof window !== "undefined" && printContainerRef.current) {
            document.title = `Referral_Form_${formDetails?.appointmentId}`;
            window.print();
            window.close();
          }
        }
      }
    }, 500);

    return () => timeout && clearTimeout(timeout);
  }, [formDetails, isImageLoaded, formDetails?.appointmentId, is_signature_available, formDetails?.doctor?.signature_uri]);

  return (
    <Box sx={{ minHeight: "100vh", width: "100vw", position: "relative", overflow: "hidden", padding: "1.5rem" }}>
      {isError ? (
        <MessageBox message="Failed to fetch diagnosis form. Please try again later." />
      ) : isLoading || !params?.appointmentId || !settings.selected_account || !settings.selected_location ? (
        <SpinnerAdv />
      ) : (
        <Box ref={printContainerRef} sx={{ overflow: "visible" }}>
          <Box
            sx={{
              fontSize: "22px",
              fontWeight: 600,
              paddingBottom: ".5rem",
              borderBottom: "1px solid black",
              marginBottom: "1.5rem",
              textAlign: "center",
            }}
          >
            Prescription Form
          </Box>

          <Box sx={{ display: "grid", gap: "1.5rem" }}>
            <Box>
              <Box sx={{ ...styles.heading }}>Doctor Details</Box>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Appointment ID</TableCell>
                    <TableCell sx={{ ...styles.tableCell }}>{formDetails?.appointmentId ?? "---"}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Name</TableCell>
                    <TableCell sx={{ ...styles.tableCell }}>{formDetails?.doctor?.display_name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Mobile Number</TableCell>
                    <TableCell sx={{ ...styles.tableCell }}>{formDetails?.doctor?.mobile}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Qualification</TableCell>
                    <TableCell sx={{ ...styles.tableCell }}>{formDetails?.doctor?.qualification}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Specialization</TableCell>
                    <TableCell sx={{ ...styles.tableCell }}>{formDetails?.doctor?.specialization}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Box sx={{ ...styles.heading }}>Patient Details</Box>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Name</TableCell>
                    <TableCell sx={{ ...styles.tableCell }}>
                      {getName(
                        formDetails?.patient?.first_name,
                        formDetails?.patient?.middle_name,
                        formDetails?.patient?.last_name
                      )}
                    </TableCell>
                  </TableRow>

                  {formDetails?.patient?.gender && (
                    <TableRow>
                      <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Gender</TableCell>
                      <TableCell sx={{ ...styles.tableCell }}>{toUpperCaseFirstLetter(formDetails?.patient?.gender)}</TableCell>
                    </TableRow>
                  )}

                  {formDetails?.patient?.date_of_birth && (
                    <TableRow>
                      <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Date of Birth</TableCell>
                      <TableCell sx={{ ...styles.tableCell }}>
                        {format(new Date(formDetails?.patient?.date_of_birth), "MM/dd/yyyy")}
                      </TableCell>
                    </TableRow>
                  )}

                  {formDetails?.patient?.date_of_birth && (
                    <TableRow>
                      <TableCell sx={{ ...styles.tableCellHeading, ...styles.tableCell }}>Age</TableCell>
                      <TableCell sx={{ ...styles.tableCell }}>{calculate_age(formDetails?.patient?.date_of_birth)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Box sx={{ ...styles.heading }}>Medications</Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      <TableCell sx={{ ...styles.heading, ...styles.tableCell, width: "5%" }} align="center">
                        No.
                      </TableCell>

                      <TableCell sx={{ ...styles.heading, ...styles.tableCell, width: "31.66%" }}>
                        Name
                      </TableCell>

                      <TableCell sx={{ ...styles.heading, ...styles.tableCell, width: "31.66%" }}>
                        Dose
                      </TableCell>
                      
                      <TableCell sx={{ ...styles.heading, ...styles.tableCell, width: "31.66%" }}>
                        Quantity
                      </TableCell>
                    </TableHead>

                    <TableBody>
                      {formDetails?.prescription_form?.medications?.map((el, idx) => (
                        <TableRow key={el.id}>
                          <TableCell sx={{ ...styles.tableCell, width: "8rem" }} align="center">
                            {idx + 1}
                          </TableCell>

                          <TableCell sx={{ ...styles.tableCell, width: "30%" }}>{el.name}</TableCell>
                          
                          <TableCell sx={{ ...styles.tableCell, width: "30%" }}>{el.dose}</TableCell>
                          
                          <TableCell sx={{ ...styles.tableCell, width: "30%" }}>{el.quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ marginTop: "1.5rem", fontSize: "16px", pageBreakBefore: "always" }}>
            {formDetails?.doctor?.display_name && (
              <Box sx={{ display: "flex" }}>
                <Box sx={{ fontWeight: 600, width: "5ch" }}>Name</Box>
                <Box sx={{ margin: "0 .5rem" }}>:</Box>
                <Box>{formDetails?.doctor?.display_name}</Box>
              </Box>
            )}

            {formDetails?.prescription_form?.submission_date && (
              <Box sx={{ display: "flex" }}>
                <Box sx={{ fontWeight: 600, width: "5ch" }}>Dated</Box>
                <Box sx={{ margin: "0 .5rem" }}>:</Box>
                <Box>{format(new Date(formDetails?.prescription_form?.submission_date), "MM/dd/yyyy")}</Box>
              </Box>
            )}

            {is_signature_available && (
              <Box sx={{ width: "15rem", height: "8rem", marginTop: "1rem", "& img": { width: "100%", objectFit: "contain" } }}>
                <Box component="img" src={formDetails?.doctor?.signature_uri} onLoad={() => setIsImageLoaded(true)} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DownloadPrescriptionForm;
