import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { Box, Grid } from "@mui/material";

import CustomTextField from "components/Reuseable/CustomTextField";
import FormWrapper from "components/Reuseable/FormWrapper";
import CustomDropdown from "components/Reuseable/CustomDropdown";
import { makeRequest } from "utilities/fetcher";
import { DOCTOR_SIGNIN } from "constants/urls";
import { useToasterContext } from "context/ToasterContext";
import { useAuthContext } from "context/AuthContext";
import { setCookie } from "utilities/setCookie";
import { setUser } from "redux/user/user.slice";
import { setIsLoggedIn } from "redux/auth/auth.slice";
import { setAccounts } from "redux/accounts/accounts.slice";
import { setSelectedAccount, setSelectedLocation } from "redux/settings/settings.slice";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useToasterContext();
  const { mutate } = useAuthContext();

  const formik = useFormik({
    initialValues: { countryCode: "+267", emailOrMobile: "", password: "" },
    validationSchema: Yup.object().shape({
      // For now it's only mobile
      emailOrMobile: Yup.string()
        .required("Please enter your mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .test("len", "Please enter a valid phone number", (val) => {
          if (!val) return true;
          else return val.toString().length === 8;
        }),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const res = await makeRequest({
        pathname: DOCTOR_SIGNIN,
        alert,
        values: {
          emailOrMobile: `${values.countryCode}${values.emailOrMobile}`,
          password: values.password,
        },
      });

      if (res?.payload?.doctor && res?.payload?.token && res?.payload?.isLoggedIn) {
        resetForm();

        if (Array.isArray(res.payload.accounts) && res.payload.accounts.length) {
          dispatch(setAccounts(res.payload.accounts));
          dispatch(setSelectedAccount(res.payload.accounts[0]._id));
          dispatch(setSelectedLocation("admin"));
        }

        dispatch(setUser(res.payload.doctor));
        dispatch(setIsLoggedIn(res.payload.isLoggedIn));

        setCookie("token", res.payload.token);
        setCookie("isLoggedIn", res.payload.isLoggedIn);

        navigate("/appointments", { replace: true });
        mutate();
      }
    },
  });

  return (
    <Box sx={{ justifySelf: "center" }}>
      <FormWrapper buttonText="Login" heading="Login into my account" rootStyles={{ width: "47rem" }} formik={formik}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <CustomDropdown
              value={formik.values.countryCode}
              disabled={true}
              menuItems={[{ title: "+267" }]}
              rootStyles={{ width: "15rem" }}
            />

            <CustomTextField
              id="emailOrMobile"
              label="Mobile Number"
              type="number"
              disabled={formik.isSubmitting}
              value={formik.values.emailOrMobile}
              handleChange={formik.handleChange}
              handleblur={formik.handleBlur}
              errors={formik.errors.emailOrMobile}
              touched={formik.touched.emailOrMobile}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            id="password"
            label="Password"
            type="password"
            disabled={formik.isSubmitting}
            value={formik.values.password}
            handleChange={formik.handleChange}
            handleblur={formik.handleBlur}
            errors={formik.errors.password}
            touched={formik.touched.password}
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center", typography: "subtitle1" }}>
          <Link to="/signup">I don't have an account</Link>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default LoginForm;
