import { Suspense } from "react";

import Box from "@mui/material/Box";

import SpinnerAdvGrid from "../SpinnerAdvGrid";

const LazyLoadingWrapper = ({ rootStyles = {}, spinnerStyles = {}, children }) => (
  <Box sx={{ minHeight: "10rem", height: "100%", position: "relative", ...rootStyles }}>
    <Suspense fallback={<SpinnerAdvGrid />}>{children}</Suspense>
  </Box>
);

export default LazyLoadingWrapper;
