import React from "react";
import { Link } from "react-router-dom";

import { Box, ListItem, ListItemButton } from "@mui/material";

// Returns the last string of a pathname
export const parsePathname = (pathname) => {
  return pathname ? pathname.split("/").pop() : "";
};

const CustomListItem = ({ data, location, expand_sidebar, isHovered }) => {
  const { href, icon, heading, comparisonString } = data;

  const Item = ({ expand_sidebar, isHovered }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        justifyContent: "space-between",
        paddingLeft: "1rem",
      }}
    >
      <Box
        sx={{
          height: "3rem",
          width: "3rem",
          "& img": { width: "100%", height: "100%", objectFit: "contain" },
        }}
      >
        <img src={icon} alt={heading} />
      </Box>

      <Box
        sx={{
          fontSize: "1.5rem",
          position: "absolute",
          left: "5rem",
          width: "max-content",
          display: expand_sidebar || isHovered ? "flex" : "none",
        }}
      >
        {heading}
      </Box>
    </Box>
  );

  return (
    <ListItem disablePadding>
      <ListItemButton
        disabled={parsePathname(location.pathname) === comparisonString ? true : false}
        sx={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          width: "100%",
          cursor: parsePathname(location.pathname) === comparisonString ? "not-allowed" : "pointer",
        }}
      >
        {href ? (
          <Link
            to={href}
            style={{
              display: "block",
              height: "100%",
              width: "100%",
              padding: ".5rem 1rem",
              textDecoration: "none",
              cursor: "pointer",
              color: "black",
            }}
          >
            <Item expand_sidebar={expand_sidebar} isHovered={isHovered} />
          </Link>
        ) : (
          <Box
            sx={{
              display: "block",
              height: "100%",
              width: "100%",
              padding: ".5rem 1rem",
              textDecoration: "none",
              cursor: "pointer",
              color: "black",
            }}
          >
            <Item expand_sidebar={expand_sidebar} isHovered={isHovered} />
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default CustomListItem;
