import axios from "axios";

import { getCookie } from "./getCookie";
import { HEADERS_WITH_JSON, HEADERS_WITH_TOKEN, HEADERS_WITH_TOKEN_AND_JSON } from "./headers";

export const makeRequest = async ({
  pathname,
  method = "POST",
  values,
  params,

  alert,
  showMessage = true,
  show_success_message = true,
  show_error_message = true,
  show_fallback = true,

  success_message,
  error_message,

  token = false,
  isFormData = false,
  abortController,
}) => {
  try {
    const fetchObj = method.toLowerCase() === "get" || method.toLowerCase() === "delete" ? {} : { data: values };

    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_URL}/${pathname}`,
      headers: token
        ? isFormData
          ? HEADERS_WITH_TOKEN(getCookie("token"))
          : HEADERS_WITH_TOKEN_AND_JSON(getCookie("token"))
        : HEADERS_WITH_JSON,
      params,
      ...(abortController?.signal && { signal: abortController.signal }),
      ...fetchObj,
    });

    if (alert && showMessage) {
      if (response.data.status === "success" && show_success_message) {
        alert(success_message ?? response.data.message, "success");
      } else if (response.data.status === "warning" && show_error_message) {
        alert(error_message ?? response.data.message, "warning");
      } else {
        show_fallback && alert(response.data.message, "error");
      }
    }

    return { ...response.data };
  } catch (err) {
    if (err.isAxiosError && alert) {
      return alert(err?.response?.data?.message ?? "Something went wrong", "error");
    }

    alert && alert("Something went wrong", "warning");
  }
};
