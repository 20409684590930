import { createSlice } from "@reduxjs/toolkit";

let initialState = { expand_sidebar: true, selected_account: "", selected_location: "", locations: [] };

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.expand_sidebar = !state.expand_sidebar;
    },
    setSelectedAccount: (state, action) => {
      state.selected_account = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selected_location = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

export const { toggleSidebar, setSelectedAccount, setSelectedLocation, setLocations } = settingsSlice.actions;
export default settingsSlice.reducer;
