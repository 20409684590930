import React from "react";

import { Container, Grid, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        padding: "8rem 0",
        backgroundColor: "#EEEEEE",
        boxShadow: 5,
        position: "relative",
        color: "white",

        "@media (max-width:900px)": { padding: "4rem 0" },
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                margin: "auto",
                width: "90%",

                "@media (max-width: 900px)": { width: "auto", margin: 0 },
              }}
            >
              <Box
                sx={{
                  fontSize: "3rem",
                  color: "custom.blue_4",
                  fontWeight: 600,
                  marginBottom: "1.5rem",

                  "@media (max-width:700px)": { fontSize: "3rem" },
                }}
              >
                Get to know us
              </Box>

              <Box
                component="ul"
                sx={{
                  listStyle: "none",
                  paddingLeft: ".5rem",

                  "& li:not(:last-child)": { marginBottom: ".5rem" },
                }}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",

                    "& a": { color: "custom.blue_3" },
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PATIENT_UI_URL}/about-medlivery`}
                  >
                    About Medlivery
                  </a>
                </Box>

                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",

                    "& a": { color: "custom.blue_3" },
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PATIENT_UI_URL}/about-us`}
                  >
                    About Us
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                margin: "auto",
                width: "max-content",

                "@media (max-width: 900px)": { width: "auto", margin: 0 },
              }}
            >
              <Box
                sx={{
                  fontSize: "3rem",
                  color: "custom.blue_4",
                  fontWeight: 600,
                  marginBottom: "1.5rem",

                  "@media (max-width:700px)": { fontSize: "3rem" },
                }}
              >
                Our Services
              </Box>

              <Box
                component="ul"
                sx={{
                  listStyle: "none",
                  paddingLeft: ".5rem",

                  "& li:not(:last-child)": { marginBottom: ".5rem" },
                }}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",
                    color: "custom.blue_3",

                    "& a": { color: "custom.blue_3" },
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PATIENT_UI_URL}/about-medlivery`}
                  >
                    Video Consultation
                  </a>
                </Box>

                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",
                    color: "custom.blue_3",

                    "& a": { color: "custom.blue_3" },
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PATIENT_UI_URL}/about-medlivery`}
                  >
                    Appointment Booking
                  </a>
                </Box>

                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",
                    color: "custom.blue_3",

                    "& a": { color: "custom.blue_3" },
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PATIENT_UI_URL}/about-medlivery`}
                  >
                    Records Management
                  </a>
                </Box>

                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",
                    color: "custom.blue_3",

                    "& a": { color: "custom.blue_3" },
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PATIENT_UI_URL}/about-medlivery`}
                  >
                    E-prescription
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                margin: "auto",
                width: "max-content",

                "@media (max-width: 900px)": { width: "auto", margin: 0 },
              }}
            >
              <Box
                sx={{
                  fontSize: "3rem",
                  color: "custom.blue_4",
                  fontWeight: 600,
                  marginBottom: "1.5rem",

                  "@media (max-width:700px)": { fontSize: "3rem" },
                }}
              >
                Contact Us
              </Box>

              <Box
                component="ul"
                sx={{
                  listStyle: "none",
                  paddingLeft: ".5rem",

                  "& li:not(:last-child)": { marginBottom: ".5rem" },
                }}
              >
                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",

                    "& a": {
                      textDecoration: "none",
                      color: "custom.blue_3",
                    },
                  }}
                >
                  <FacebookIcon
                    sx={{
                      fontSize: "3.5rem",
                      marginRight: "1rem",
                      color: "#696969",
                    }}
                  />

                  <a href="https://www.facebook.com/medlivery.bw/" target="_blank" rel="noreferrer">
                    @medlivery.bw
                  </a>
                </Box>

                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",

                    "& a": {
                      textDecoration: "none",
                      color: "custom.blue_3",
                    },
                  }}
                >
                  <InstagramIcon
                    sx={{
                      fontSize: "3.5rem",
                      marginRight: "1rem",
                      color: "#696969",
                    }}
                  />

                  <a href="https://www.instagram.com/medlivery.bw/" target="_blank" rel="noreferrer">
                    @medlivery.bw
                  </a>
                </Box>

                <Box
                  component="li"
                  sx={{
                    fontSize: "1.8rem",
                    lineHeight: "3.2rem",
                    display: "flex",
                    alignItems: "center",

                    "& a": { textDecoration: "none", color: "custom.blue_3" },
                  }}
                >
                  <PhoneIcon
                    sx={{
                      fontSize: "3.5rem",
                      marginRight: "1rem",
                      color: "#696969",
                    }}
                  />

                  <Box sx={{ color: "black" }}>
                    <Box>+26776 364834</Box>
                    <Box>+26775 910502</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              color: "custom.blue_4",
              textAlign: "center",
              fontSize: "2rem",
              paddingTop: "4rem  !important",
            }}
          >
            Copyright © 2023, Nexus Technologies. All rights reserved.
            <Box
              sx={{
                fontSize: "1.5rem",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Box component="span" sx={{ cursor: "pointer", "& a": { color: "blue" } }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_PATIENT_UI_URL}/terms-of-use`}
                >
                  Terms of Use
                </a>
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: "1.5rem",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              v1.0.012923
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
